import { Canvas } from '@thive/canvas';
import { create } from 'zustand';

type CanvasEventState = {
  instance: Canvas | undefined;
  mousePosition: { x: number; y: number };
  zoom: number;
};

type CanvasEventAction = {
  initialize(instance: Canvas): void;
  dispose(): void;
  setZoom(value: number): void;
  setMousePosition(x: number, y: number): void;
};

const INITIAL_STATE: CanvasEventState = {
  instance: undefined,
  mousePosition: { x: 0, y: 0 },
  zoom: 100,
};

// NOTE: no devtools for this store. Redux devtools not suited for canvas instance

export const useCanvasStore = create<CanvasEventState & CanvasEventAction>()((set, get) => ({
  ...INITIAL_STATE,
  initialize: (instance: Canvas) => {
    set({ instance });
  },
  dispose: () => {
    get().instance?.dispose();
    set({ instance: undefined });
  },
  setMousePosition(x: number, y: number) {
    set({ mousePosition: { x, y } });
  },
  setZoom(value: number) {
    set({ zoom: value });
  },
}));
