import { Box, Stack } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { Accordion } from '@/modules/common/components/Accordion';
import { allVehiclesState, vehicleCapacitySelector } from '@/modules/vehicles';
import { NumberInput } from '@common/components/inputs';
import { OverflowTypography } from '@common/components/OverflowTypography';
import { StyledSlider } from '@common/components/StyledSlider';
import { StyledTooltip } from '@common/components/StyledTooltip';
import { useDebouncedCallback } from '@modules/common/hooks';
import { CONTEXT, contextState } from '@recoil/input';
import { useSimulationDraftCallbacks } from '../../../hooks';
import { isVehicleRangeSelector } from '../../../store/draft';
import { VehicleTypeLoadTimes } from './VehicleTypeLoadTimes';

type Props = {
  name: string;
  range: number[];
};

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
];

export function VehicleType({ range, name }: Props) {
  const containerRef = useRef<HTMLDivElement>();
  const { updateVehicleRange, updateVehicleCount } = useSimulationDraftCallbacks();
  const setContext = useSetRecoilState(contextState);
  const { t } = useTranslation();
  const allVehicles = useRecoilValue(allVehiclesState);
  const isRange = useRecoilValue(isVehicleRangeSelector);
  const vehicle = allVehicles.find((item) => item.name === name);
  const [rangeLimitNote, setRangeLimitNote] = useState('');
  const removeRangeLimitNoteDebounced = useDebouncedCallback(() => setRangeLimitNote(''), 3000);

  const onRangeChange = useRecoilCallback(
    ({ snapshot }) =>
      async (_: Event, range: number[]) => {
        const [from, to] = range;
        if (from === to) return;

        if (!vehicle) return;

        const capacity = await snapshot.getPromise(vehicleCapacitySelector(vehicle.id));

        if (to > capacity) {
          setRangeLimitNote(
            `${t(
              'interface:simulation.simulation_edit.vehicle_types.vehicle_limit_label',
              'Vehicle limit',
            )}: ${capacity}`,
          );
          removeRangeLimitNoteDebounced();
        } else {
          updateVehicleRange(name, from, to);
          setRangeLimitNote('');
        }
      },
    [vehicle, t, removeRangeLimitNoteDebounced, updateVehicleRange, name],
  );

  const onCountChange = useRecoilCallback(
    ({ snapshot }) =>
      async (count: number) => {
        try {
          // @ts-expect-error strictNullChecks. Pls fix me
          const capacity = await snapshot.getPromise(vehicleCapacitySelector(vehicle.id));

          if (count > capacity) {
            updateVehicleCount(name, capacity);
            throw new Error(
              `${t(
                'interface:simulation.simulation_edit.vehicle_types.floorplan_capacity',
                'The floorplan capacity for this vehicle is',
              )}: ${capacity}`,
            );
          } else {
            updateVehicleCount(name, count);
          }
        } catch (e) {
          return { error: e.message };
        }
      },
    [vehicle, updateVehicleCount, name, t],
  );

  const onVehicleCountFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const onVehicleCountBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);
  const label = name + (vehicle ? '' : ` (${t('errors:simulation.edit.vehicle.unavailable')})`);

  return !vehicle ? (
    <OverflowTypography>{label}</OverflowTypography>
  ) : !isRange ? (
    <Accordion
      // @ts-expect-error strictNullChecks. Pls fix me
      ref={containerRef}
      title={
        <Stack direction='row' alignItems='center' width='100%'>
          <Stack direction='row' alignItems='center' width={3 / 4}>
            <img src={vehicle?.image.url} alt={label} width={32} height={32} />
            <OverflowTypography>{label}</OverflowTypography>
          </Stack>
          <NumberInput
            sx={{ width: 1 / 4 }}
            ariaLabel={label}
            name={`vehicleCount_${name}`}
            errorType='floating'
            value={range[0]}
            disabled={!vehicle}
            // @ts-expect-error strictNullChecks. Pls fix me
            onChange={onCountChange}
            dataType='integer'
            onFocus={onVehicleCountFocus}
            onBlur={onVehicleCountBlur}
          />
        </Stack>
      }
    >
      <VehicleTypeLoadTimes vehicleName={vehicle.name} />
    </Accordion>
  ) : (
    <Stack flex='1' direction='column' justifyContent='space-between' alignItems='center'>
      <Stack
        flex='1'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          width: '100%',
        }}
      >
        <Stack flexDirection='row' alignItems='center'>
          <img src={vehicle?.image.url} alt='' width={32} height={32} />
          <OverflowTypography>{label}</OverflowTypography>
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', width: '100%' }}>
        <Box sx={{ px: 1, width: '100%', flex: 1, mt: 2, mb: 4 }}>
          <StyledTooltip title={rangeLimitNote} placement='bottom-start'>
            <Stack>
              <StyledSlider
                getAriaLabel={() => label}
                getAriaValueText={(value) => value.toString()}
                value={[range[0], range[range.length - 1]]}
                onChange={onRangeChange}
                valueLabelDisplay='auto'
                max={25}
                min={1}
                marks={marks}
                size='small'
                disableSwap
              />
            </Stack>
          </StyledTooltip>
        </Box>
      </Stack>
      <VehicleTypeLoadTimes vehicleName={vehicle.name} />
    </Stack>
  );
}
