import { LayerNames } from '@/modules/common/types/layers';
import { ShapeType } from '@/modules/common/types/shapes';



export const getSubLayerNameFromLayer = (type: LayerNames): LayerNames[] => {
  switch (type) {
    case LayerNames.NAMES:
      return [LayerNames.AREA_NAMES, LayerNames.HIGHWAY_NAMES, LayerNames.OBSTACLE_NAMES]
    case LayerNames.AREA_NAMES:
      return [LayerNames.NAMES]
    case LayerNames.HIGHWAY_NAMES:
      return [LayerNames.NAMES]
    case LayerNames.OBSTACLE_NAMES:
      return [LayerNames.NAMES]
    default: {
      return [];
    }
  }
};

export const getParentLayerGroupFromShapeName = (type: ShapeType): LayerNames | undefined => {
  switch (type) {
    case ShapeType.INTAKE:
    case ShapeType.CHARGING:
    case ShapeType.DELIVERY:
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_TWO_EP:
    case ShapeType.STORAGE:
    case ShapeType.PARKING:
      return LayerNames.AREA_NAMES
    case ShapeType.HIGHWAY_ANGLED:
    case ShapeType.HIGHWAY:
      return LayerNames.HIGHWAY_NAMES
    case ShapeType.OBSTACLE:
      return LayerNames.OBSTACLE_NAMES
    default:
      return undefined;
  }
};

export const getRelativeLayers = (type: LayerNames): LayerNames[] => {
  switch (type) {
    case LayerNames.INTAKE:
    case LayerNames.DELIVERY:
    case LayerNames.STORAGE:
    case LayerNames.PARKING:
    case LayerNames.CHARGING:
    case LayerNames.PROCESS:
      return [LayerNames.AREAS];
    case LayerNames.HIGHWAYS:
    case LayerNames.ANGLED_HIGHWAYS:
      return [LayerNames.ROADS];
    case LayerNames.INTERIOR:
      return [LayerNames.OBSTACLES, LayerNames.WALLS]
    case LayerNames.OBSTACLES:
    case LayerNames.WALLS:
      return [LayerNames.INTERIOR]
    case LayerNames.AREA_NAMES:
    case LayerNames.HIGHWAY_NAMES:
    case LayerNames.OBSTACLE_NAMES:
      return [LayerNames.NAMES];
    case LayerNames.AREAS:
      return [
        LayerNames.INTAKE,
        LayerNames.DELIVERY,
        LayerNames.STORAGE,
        LayerNames.PARKING,
        LayerNames.CHARGING,
        LayerNames.PROCESS,
      ];
    case LayerNames.ROADS:
      return [LayerNames.HIGHWAYS, LayerNames.ANGLED_HIGHWAYS];
    case LayerNames.NAMES:
      return [LayerNames.AREA_NAMES, LayerNames.HIGHWAY_NAMES, LayerNames.OBSTACLE_NAMES];
    default:
      return [];
  }
};