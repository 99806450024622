import { ListItemButton } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { removeItem } from '@/modules/common/helpers/array';
import { Label } from '@/modules/layers/components/common/Label';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import {
  addInvisibleElementsSelector,
  addLockedElementSelector,
  invisibleElementSelector,
  lockedElementSelector,
  removeInvisibleElementsSelector,
  removeLockedElementSelector,
} from '@modules/layers';
import { LockBtn } from './LockBtn';
import { VisibilityBtn } from './VisibilityBtn';

const ShapeItemComponent = ({
  label,
  id,
  level,
  canBeLocked = true,
  canBeHidden = true,
  disabled = false,
}) => {
  const elementIsVisible = useRecoilValue(invisibleElementSelector(id));
  const hideElements = useSetRecoilState(addInvisibleElementsSelector);
  const showElements = useSetRecoilState(removeInvisibleElementsSelector);
  const locked = useRecoilValue(lockedElementSelector(id));
  const addLocked = useSetRecoilState(addLockedElementSelector);
  const removeLocked = useSetRecoilState(removeLockedElementSelector);
  const [hover, setHover] = useState(false);
  const [selectedShapesIds, setSelectedShapesIds] = useRecoilState(selectedShapesIdsState);
  const [selected, setSelected] = useState(false);

  const toggleShow = useCallback(() => {
    if (elementIsVisible) {
      hideElements([id]);
    } else {
      showElements([id]);
    }
  }, [elementIsVisible, hideElements, id, showElements]);

  const toggleLock = useCallback(() => {
    if (locked) {
      removeLocked(id);
    } else {
      addLocked(id);
    }
  }, [addLocked, id, locked, removeLocked]);

  const startHover = useCallback(() => {
    setHover(true);
  }, []);

  const endHover = useCallback(() => {
    setHover(false);
  }, []);

  useEffect(() => {
    if (
      selectedShapesIds.length &&
      (!elementIsVisible || locked) &&
      selectedShapesIds.includes(id)
    ) {
      let newSelectedShapesIds = removeItem(selectedShapesIds, id);
      setSelectedShapesIds(newSelectedShapesIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locked, elementIsVisible]);

  useEffect(() => {
    if (selectedShapesIds.length) {
      if (selectedShapesIds.includes(id)) {
        setSelected(true);
        return;
      }
    }
    setSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShapesIds]);

  const containerStyle = useMemo(
    () => ({
      paddingLeft: 4 + 2 * level,
      color: !elementIsVisible ? 'neutral.main' : 'neutral.darkest',
      backgroundColor: selected ? 'neutral.grey' : 'shades.light',
      borderColor: selected ? 'neutral.grey' : 'shades.light',

      '&:hover': {
        backgroundColor: selected ? 'neutral.grey' : 'shades.light',
      },
    }),
    [elementIsVisible, level, selected],
  );

  return (
    <ListItemButton
      alignItems='center'
      onMouseOver={startHover}
      onMouseLeave={endHover}
      sx={containerStyle}
    >
      <Label labelText={label} />

      {canBeLocked && (hover || locked) && (
        <LockBtn
          isLocked={locked}
          isVisible={elementIsVisible}
          disabled={disabled}
          onClick={toggleLock}
        />
      )}

      {canBeHidden && ( hover || locked) && (
        <VisibilityBtn eyeCrossed={!elementIsVisible} onClick={toggleShow} />
      )}
    </ListItemButton>
  );
};

export const ShapeItem = memo(ShapeItemComponent);
