import { useFloorPlanService as fpsService } from '@/modules/floorplanService';
import { useCanvasStore } from '@modules/canvas';
import { useCallback, useEffect } from 'react';

import {
  useFloorPlanStore,
  useGateStore,
  useKollmorgenStore,
  useLayoutStore,
  useNavitechStore,
  useSevenSenseStore,
} from '../store';
import { useApi } from './useApi';

export const useLoadData = () => {
  const { generateFloorPlanArtefacts } = fpsService();
  const { fetchLocalizationMapFiles, fetch } = useApi();

  const load = useCallback(async () => {
    const [layout, artifacts] = await Promise.all([fetch(), generateFloorPlanArtefacts()]);
    const files = await fetchLocalizationMapFiles(layout?.localizationMaps ?? []);

    await useKollmorgenStore.getState().initialize(files.kollmorgen || undefined);
    await useSevenSenseStore.getState().initialize(files.sevenSense || undefined);
    await useNavitechStore.getState().initialize(files.navitech || undefined);
    await useFloorPlanStore.getState().initialize(artifacts);
    useLayoutStore.getState().initialize(layout?.layoutDelta);
    useGateStore.getState().initialize(artifacts, layout?.gates ?? []);
    useCanvasStore.getState().instance?.zoomToFit();
  }, [fetch, fetchLocalizationMapFiles, generateFloorPlanArtefacts]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
