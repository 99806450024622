import { useAxios } from '@/modules/api/hooks';
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { SxProps } from '@mui/system';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Lazy } from '../../common/components/Lazy';

const Vanderlande = React.lazy(() => import(`../../../assets/logos/VanderlandeLogo`));
const ToyotaForklifts = React.lazy(() => import(`../../../assets/logos/ToyotaForkliftsLogo`));
const BastianSolutions = React.lazy(() => import(`../../../assets/logos/BastianSolutionsLogo`));
const ToyotaShokki = React.lazy(() => import(`../../../assets/logos/ToyotaShokkiLogo`));
const THive = React.lazy(() => import(`../../../assets/logos/THiveLogo`));

const getLogo = (Component: React.LazyExoticComponent<(props: any) => JSX.Element>) =>
  function Logo({ sx, ...props }: JSX.IntrinsicAttributes & { sx: SxProps<{}> }) {
    return (
      <Lazy>
        <Component {...props} sx={sx} />
      </Lazy>
    );
  };

const VanderlandeLogo = getLogo(Vanderlande);
const ToyotaForkliftsLogo = getLogo(ToyotaForklifts);
const BastianSolutionsLogo = getLogo(BastianSolutions);
const ToyotaShokkiLogo = getLogo(ToyotaShokki);
const THiveLogo = getLogo(THive);

function mapCompanyNameToLogo(name: string): typeof THiveLogo {
  switch (name) {
    case 'vanderlande':
      return VanderlandeLogo;

    case 'toyota-industries':
    case 'toyota-forklifts':
      return ToyotaForkliftsLogo;

    case 'bastiansolutions':
      return BastianSolutionsLogo;

    case 'toyota-shokki':
      return ToyotaShokkiLogo;

    default:
      return THiveLogo;
  }
}

export type UseUserInfo = {
  accountInfo: AccountInfo | null;
  getName: () => string;
  getEmail: () => string;
  getAvatarImage: () => Promise<string | undefined>;
  getCompanyLogoFromEmail: () => typeof THiveLogo | undefined;
  signOut: () => void;
};

export function useUserInfo(): UseUserInfo {
  const { instance, accounts } = useMsal();
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);

  const scopes = useMemo(() => ['user.read', 'profile'], []);
  const axiosI = useAxios(
    { baseURL: 'https://graph.microsoft.com/v1.0', scopes },
    { responseType: 'blob' },
  );

  useEffect(() => {
    if (!accounts || accounts.length < 1) return;
    setAccountInfo(accounts[0]);
  }, [accounts]);

  const signOut = useCallback(async (): Promise<void> => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }, [instance]);

  const getName = useCallback(
    (): string | undefined => accountInfo?.idTokenClaims?.name,
    [accountInfo?.idTokenClaims?.name],
  );

  const getAvatarImage = useCallback(async (): Promise<string | undefined> => {
    let avatarUrl: string;
    try {
      const blob = await axiosI.get('/me/photo/$value');
      const url = window.URL || window.webkitURL;
      avatarUrl = url.createObjectURL(blob.data);
    } catch (err) {
      return '';
    }

    return avatarUrl;
  }, [axiosI]);

  const getEmail = useCallback((): string | undefined => {
    if (!accountInfo) return undefined;

    const { idTokenClaims, username } = accountInfo;
    // @ts-expect-error strictNullChecks. Pls fix me
    if (!idTokenClaims) return username?.includes('@') && username;

    const { emails, preferred_username } = idTokenClaims;
    if (emails && emails.length > 0) return emails[0];

    // @ts-expect-error strictNullChecks. Pls fix me
    return preferred_username?.includes('@') && preferred_username;
  }, [accountInfo]);

  const getCompanyLogoFromEmail = useCallback((): typeof THiveLogo | undefined => {
    const email = getEmail();
    if (!email) return undefined;

    const result = email.match(/@.*\./gi);
    // @ts-expect-error strictNullChecks. Pls fix me
    if (result.length === 0) return undefined;

    // @ts-expect-error strictNullChecks. Pls fix me
    const [emailTail] = result;
    const companyName = emailTail.substring(1, emailTail.length - 1);

    return mapCompanyNameToLogo(companyName);
  }, [getEmail]);

  return {
    accountInfo,
    // @ts-expect-error strictNullChecks. Pls fix me
    getName,
    getAvatarImage,
    // @ts-expect-error strictNullChecks. Pls fix me
    getEmail,
    signOut,
    getCompanyLogoFromEmail,
  };
}
