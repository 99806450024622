import { ListItemButton, ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ArrowSmallDown, ArrowSmallRight } from '@/assets/icons';
import { FlowsOpacity } from '@/components/SettingsPanel/components/FlowsOpacity';
import { LayerNames } from '@/modules/common/types/layers';
import { ShapeItem } from '@/modules/layers/components';
import { Label } from '@/modules/layers/components/common/Label';
import { getLayerOfShapeType } from '@/modules/workspace/helpers/shape';
import { selectedShapesIdsState, selectedShapesState } from '@/store/recoil/shapes/selected';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useFloorPlanState } from '@modules/floorplan';
import { useUpdateLayerUserPreference } from '@modules/layers/hooks';
import {
  layerContainsSelectedShapes,
  layersLockSelector,
  layersShowSelector,
  layerVisabilityToggleSelector
} from '@modules/layers/store/layersSelector';
import { LockBtn } from './LockBtn';
import { VisibilityBtn } from './VisibilityBtn';

type LayerItemProps = {
  name: LayerNames;
  label: string;
  level: number;
  elements?: any[];
  groups?: { name: LayerNames; label: string; elements: any[] }[];
};

const LayerItemComponent = ({ name, label, level, elements = [], groups = [] }: LayerItemProps) => {
  const { updateVisibility } = useUpdateLayerUserPreference();
  const { saveFloorPlan } = useFloorPlanState();

  const [toggleRelativesVisability, setToggleRelativesVisability] = useRecoilState(layerVisabilityToggleSelector(name))
  const [layerIsVisible, setLayerIsVisible] = useRecoilState(layersShowSelector(name));
  const [locked, setLocked] = useRecoilState(layersLockSelector(name));

  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFlowOpacityInputFocused, setIsFlowOpacityInputFocused] = useState(false);
  const selected = useRecoilValue(layerContainsSelectedShapes(name));
  const mode = useRecoilValue(modeSelector);

  const removeLayerFromSelection = useRecoilCallback(
    ({ set, snapshot }) =>
      async (layerName: LayerNames) => {
        if (!selected) return;

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        if (!selectedShapes || selectedShapes.length === 0) return;

        const newSelectedShapesIds = [];
        selectedShapes.forEach((shape) => {
          if (layerName !== getLayerOfShapeType(shape.type)) {
            // @ts-expect-error strictNullChecks. Pls fix me
            newSelectedShapesIds.push(shape.id);
          }
        });

        if (selected) {
          set(selectedShapesIdsState, newSelectedShapesIds);
        }
      },
    [selected],
  );

  const toggleShow = useCallback(() => {
    setLayerIsVisible(!layerIsVisible);
    updateVisibility(name, !layerIsVisible);
    setToggleRelativesVisability(name);
    if (mode == WorkspaceMode.EDITABLE) {
      saveFloorPlan();
    }
  }, [mode, saveFloorPlan, name, updateVisibility, layerIsVisible, setLayerIsVisible, setToggleRelativesVisability]);

  const toggleLock = useCallback(() => {
    setLocked((locked) => !locked);
    saveFloorPlan();
  }, [saveFloorPlan, setLocked]);

  const startHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleFocusFlowsOpacity = useCallback(() => {
    setIsFlowOpacityInputFocused(true);
  }, []);

  const handleBlurFlowsOpacity = useCallback(() => {
    setIsFlowOpacityInputFocused(false);
  }, []);

  const endHover = useCallback(() => {
    setHover(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  // if layer is getting locked or hidden, clean up the selectedShapesState to avoid unwanted deletion
  useEffect(() => {
    if (locked || !layerIsVisible) removeLayerFromSelection(name);
  }, [name, locked, layerIsVisible, removeLayerFromSelection]);

  const containerStyle = useMemo(
    () => ({
      paddingLeft: 1 + 2 * level,
      color: !layerIsVisible ? 'primary.light' : 'primary.main',
      backgroundColor: selected ? 'neutral.grey' : 'shades.light',
      borderColor: selected ? 'neutral.grey' : 'shades.light',

      '&:hover': {
        backgroundColor: selected ? 'neutral.grey' : 'shades.light',
      },
    }),
    [layerIsVisible, level, selected],
  );

  const disabled = mode !== WorkspaceMode.EDITABLE;

  return (
    <>
      <ListItemButton
        alignItems='center'
        onMouseOver={startHover}
        onMouseLeave={endHover}
        disableRipple
        sx={containerStyle}
      >
        <ListItemIcon
          onClick={handleClick}
          sx={{
            minWidth: (theme) => theme.spacing(3),
            fontSize: 24,
          }}
        >
          {elements.length > 0 || groups.length > 0 ? (
            open ? (
              <ArrowSmallDown data-testid='Arrow Small Down'/>
            ) : (
              <ArrowSmallRight data-testid= 'Arrow Small Right'/>
            )
          ) : null}
        </ListItemIcon>
        <Label labelText={label} />
        {((name === LayerNames.FLOWS && (hover || locked)) || isFlowOpacityInputFocused) && (
          <FlowsOpacity onFocus={handleFocusFlowsOpacity} onBlur={handleBlurFlowsOpacity} />
        )}

        {( hover || locked) && (
          <LockBtn
            isLocked={locked}
            isVisible={layerIsVisible}
            disabled={disabled}
              onClick={toggleLock}
          />
        )}

        {(hover || locked) && (
          <VisibilityBtn eyeCrossed={!layerIsVisible} onClick={toggleShow} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {elements.map((shape) => (
            <ShapeItem
              key={shape.id}
              label={shape.name}
              id={shape.id}
              level={level + 1}
              disabled={disabled}
            />
          ))}
          {groups.map((group) => (
            <LayerItem
              key={group.name}
              name={group.name}
              level={level + 1}
              label={group.label}
              elements={group.elements}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const LayerItem = memo(LayerItemComponent);
