import { Box, Button, CircularProgress, Stack } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { ExportOutput } from '@components/NavigationBar/ExportOutput';
import { ErrorIcon, FileAdd, MinusIcon } from '@/assets/icons';
import { FloorplanServiceOption, Status } from '../enum';
import { useAutomaticValidation } from '../hooks';
import { fpsFilesSelector, fpsWarningsSelector, validationStatus } from '../store';
import { Panel } from './Panel';
import { theme } from '@/modules/common/components/theme';
import { useErrorMessageApi } from '@/modules/Notifications/hooks/useErrorMessageApi';
import { allShapesSelector } from '@/store/recoil/shapes';
import { formatMessage } from '@/modules/Notifications/helpers';
import { FormattedShape, FormattedWarning } from '../types';
import { useTrackFloorplanMetric } from '@/modules/insight/hooks/useTrackFloorplanMetric';

export const ValidationPanel = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const files = useRecoilValue(fpsFilesSelector);
  const status = useRecoilValue(validationStatus);
  const [seconds, setSeconds] = useState(3);
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation(['interface', 'common']);
  const { validate } = useAutomaticValidation();
  const warnings = useRecoilValue(fpsWarningsSelector);
  const [formattedWarnings, setFormattedWarnings] = useState<FormattedWarning[]>([]);
  const { getErrorDetails } = useErrorMessageApi();
  const trackFloorplanMetric = useTrackFloorplanMetric();

  const progressStyle = {
    backgroundColor: theme.palette.primary.main,
    height: '5px',
    width: status === Status.WaitingToExecute ? '100%' : '0%',
    transition: status === Status.WaitingToExecute ? '3s' : '0s',
  };

  useEffect(() => {
    if (status === Status.WaitingToExecute) {
      reset();
      startTimer();
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const startTimer = useCallback(() => {
    setSeconds(3);
    setIsActive(true);
  }, []);

  const reset = useCallback(() => {
    setSeconds(3);
    setIsActive(false);
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      // @ts-expect-error strictNullChecks. Pls fix me
      interval = setInterval(() => {
        let nextValue = seconds - 1;
        if (nextValue < 0) nextValue = 0;
        setSeconds((seconds) => nextValue);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      // @ts-expect-error strictNullChecks. Pls fix me
      clearInterval(interval);
    }
    // @ts-expect-error strictNullChecks. Pls fix me
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const handlePanelClick = useCallback(() => {
    if (files === null) {
      const startTime = performance.now();
      validate(FloorplanServiceOption.Validate).then(() => {
        trackFloorplanMetric('VALIDATE_FLOORPLAN', startTime);
      });
    }

    setOpen((prevOpen) => !prevOpen);
  }, [files, validate, trackFloorplanMetric]);

  const handleClickAway = useCallback((event: Event) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }, []);

  const getCurrentIcon = useCallback(() => {
    if (status === Status.WaitingToExecute) return null;
    if (status === Status.WaitingForResponse)
      return <CircularProgress size={15} sx={{ marginRight: '0.25em' }} />;
    if (status === Status.Error) return <ErrorIcon width={24} height={24} />;
    if (files !== null) return <FileAdd />;
    return <MinusIcon />;
  }, [files, status]);

  const getPopperContent = useCallback(() => {
    if (status === Status.WaitingToExecute)
      return (
        <Box>
          <Panel variant='text' content='...' />
        </Box>
      );
    if (status === Status.WaitingForResponse)
      return (
        <Box>
          <Panel variant='loadingAnimation' content='' />
        </Box>
      );
    if (status === Status.Error)
      return (
        <Box>
          <Panel
            variant='text'
            content={t('interface:export.message_error', 'There was a problem generating output')}
          />
        </Box>
      );
    if (files !== null)
      return (
        <Box>
          <ExportOutput output={files} warnings={warnings} formattedWarnings={formattedWarnings} />
        </Box>
      );
    return (
      <Box>
        <Panel variant='text' content='No files available' />
      </Box>
    );
  }, [files, formattedWarnings, status, t, warnings]);

  const formatWarningMessages = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const allShapes = await snapshot.getPromise(allShapesSelector);
        const allShapeIds = allShapes.map((shape) => shape.id);

        setFormattedWarnings(await Promise.all(warnings.map(async (warning) => {
          const ids = warning.args.filter((arg) => allShapeIds.includes(arg.argument.split('_')[0]));
          const names = warning.args.filter(
            (arg) => !allShapeIds.includes(arg.argument.split('_')[0])
          );

          const shapes: FormattedShape[] = [];
          for (let i = 0; i < ids.length; i++) {
            if (names.length > i) {
              shapes.push({
                name: names[i].argument.split('_')[0],
                id: ids[i].argument.split('_')[0],
                sequence: i,
              });
            }
          }
          
          const newErrorNotification = await getErrorDetails(warning.code);
          const timestamp = new Date(newErrorNotification.timestamp);
          const formattedDate = new Intl.DateTimeFormat('en-GB', {
            day: 'numeric',
            month: 'short',
          }).format(timestamp);

          return ({
            description: formatMessage(newErrorNotification.description, warning.args.map(arg => arg.argument.split('_')[0])),
            title: newErrorNotification.title,
            shapes,
            sequence: warning.sequence,
            severity: newErrorNotification.severity,
            readMore: String(newErrorNotification.readMoreUrl),
            timeStamp: formattedDate,
            errorCode: String(newErrorNotification.code)
          });
        })));
      },
    [getErrorDetails, warnings],
  );

  useEffect(() => {
    setFormattedWarnings([]);
    formatWarningMessages();
  }, [formatWarningMessages]);

  const getLabel = useCallback(() => {
    if (status === Status.WaitingToExecute)
      return t(`interface:export.validation_panel_label.waiting_to_execute`, {
        seconds: Math.ceil(seconds),
      });
    if (status === Status.WaitingForResponse)
      return t(`interface:export.validation_panel_label.waiting_for_response`);
    if (status === Status.Error) return t(`interface:export.validation_panel_label.desgin_failed`);
    if (files !== null && warnings.length > 0)
      return t(`interface:export.validation_panel_label.desgin_failed`);
    if (files !== null) return t(`interface:export.validation_panel_label.desgin_passed`);
    return t('common:validation', 'Validation');
  }, [files, seconds, status, t, warnings.length]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-label='Validation'
        variant='text'
        onClick={handlePanelClick}
        startIcon={getCurrentIcon()}
        sx={{
          width: '200px',
          border: 0, color: theme.palette.neutral.dark,
          '&:hover': {
            backgroundColor: theme.palette.shades.light,
            color: theme.palette.neutral.darker
          }
        }}
      >
        {getLabel()}
      </Button>
      <div style={{ backgroundColor: theme.palette.neutral.lighter }}>
        <div style={progressStyle} />
      </div>
      <Popper
        sx={{ zIndex: 1 }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-4, 8],
            },
          },
        ]}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper style={{ maxHeight: 500, overflow: 'auto' }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                {getPopperContent()}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
