import { useEffect } from 'react';
import { CanvasZoomEventArgs, PointerEventArgs } from '@thive/canvas';

import { useCanvasStore } from '../store';

export const useCanvasEventSetup = () => {
  useEffect(() => {
    const canvas = useCanvasStore.getState().instance;
    const onCanvasZoom = (e: CanvasZoomEventArgs) => useCanvasStore.getState().setZoom(e.value);
    const onPointerMove = (e: PointerEventArgs) =>
      useCanvasStore.getState().setMousePosition(e.position.x, e.position.y);

    canvas?.on('Canvas.Zoom', onCanvasZoom, { throttle: 50 });
    canvas?.on('MouseEvents.PointerMove', onPointerMove, { throttle: 50 });
    return () => {
      canvas?.off('Canvas.Zoom', onCanvasZoom);
      canvas?.off('MouseEvents.PointerMove', onPointerMove);
    };
  }, []);
};
