import { SxProps, TextField, Theme } from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import InputAdornment from '@mui/material/InputAdornment';
import {
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { parseNumberData } from '@modules/common/components/inputs/helpers/parseNumberData';
import { useEnterBlur } from '@modules/common/components/inputs/hooks/useEnterBlur';
import { OnChangeHandler } from '@modules/common/components/inputs/types';
import { StyledTooltip } from '@modules/common/components/StyledTooltip';

type Props = {
  ariaLabel?: string;
  disabled?: boolean;
  name?: string;
  value: number;
  minValue?: number;
  minValueErrorMsg?: string;
  unit?: string;
  prefix?: string | JSX.Element;
  inputProps?: Partial<InputProps>;
  sx?: SxProps<Theme>;
  FormHelperTextSx?: SxProps<Theme>;
  error?: string;
  errorType?: 'normal' | 'floating';
  changeParamType?: 'value' | 'event';
  clearTouch?: boolean;
  dataType?: 'integer' | 'decimal';
  enterBlur?: boolean;
  onBlur?: () => void;
  onChange?: OnChangeHandler<number> | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onClick?: () => void;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  tooltip?: string;
};

export function NumberInput({
  ariaLabel,
  disabled = false,
  error,
  errorType = 'normal',
  dataType = 'decimal',
  name,
  inputProps,
  sx,
  FormHelperTextSx,
  value,
  minValue,
  minValueErrorMsg,
  prefix,
  unit,
  enterBlur = true,
  onChange,
  onClick,
  onBlur,
  onFocus,
  changeParamType = 'value',
  tooltip,
  clearTouch, // Fix for firing events when changing values,
}: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [touched, setTouched] = useState(false);
  const [inputType, setInputType] = useState('text');
  const [innerValue, setInnerValue] = useState<number>(value);
  const [innerError, setInnerError] = useState<string | undefined>();
  const eventRef = useRef(null);
  const onBlurInner = useCallback(() => {
    setIsFocused(false);
    setInnerValue(value);
    onBlur?.();
  }, [onBlur, value]);

  // @ts-expect-error strictNullChecks. Pls fix me
  const { onKeyUp } = useEnterBlur(ref);

  const onFocusInner: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (evt) => {
      setIsFocused(true);
      evt.target.select();
      if (onFocus) {
        onFocus?.(evt);
      }
    },
    [onFocus],
  );

  const onChangeInner = useCallback(
    (e) => {
      const value = (e.target as HTMLInputElement).valueAsNumber;
      eventRef.current = e;
      setInnerValue(parseNumberData(dataType, value));
      setTouched(true);
    },
    [dataType],
  );

  useEffect(() => {
    setInputType(isFocused ? 'number' : 'text');
  }, [isFocused]);

  useEffect(() => {
    if (touched) {
      // @ts-expect-error strictNullChecks. Pls fix me
      const result = onChange?.(changeParamType === 'value' ? innerValue : eventRef.current);
      Promise.resolve(result).then((result) => {
        const error =
          (result && result.error) || (minValue && innerValue < minValue && minValueErrorMsg);
        setInnerError(error || undefined);
      });

      if (clearTouch) {
        setTouched(false);
      }
    } else if (minValue || minValue === 0) {
      setInnerError(innerValue < minValue ? minValueErrorMsg : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerValue, minValue]);

  useEffect(() => {
    if (value) {
      setInnerValue(value);
      return;
    }

    setInnerValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onClickHandler: MouseEventHandler<HTMLElement> = useCallback(
    (evt) => {
      const target = evt.target as HTMLInputElement | HTMLTextAreaElement;
      target.focus();
      if (onClick) {
        onClick?.();
      }
    },
    [onClick],
  );

  return (
    <StyledTooltip title={tooltip}>
      <TextField
        inputRef={ref}
        name={name}
        disabled={disabled}
        error={!!(error ?? innerError)}
        type={inputType}
        helperText={error ?? innerError}
        value={innerValue?.toString()}
        variant='filled'
        onBlur={onBlurInner}
        onChange={onChangeInner}
        onClick={onClickHandler}
        onFocus={onFocusInner}
        onKeyUp={enterBlur ? onKeyUp : undefined}
        sx={sx}
        InputProps={{
          ...inputProps,
          inputProps: {
            'aria-label': ariaLabel,
          },
          disableUnderline: true,
          startAdornment:
            prefix &&
            (typeof prefix === 'string' ? (
              <InputAdornment position='end'>{prefix}</InputAdornment>
            ) : (
              <InputAdornment sx={{ marginBottom: 2 }} position='start'>
                {prefix}
              </InputAdornment>
            )),
          endAdornment: unit && <InputAdornment position='end'>{unit}</InputAdornment>,
        }}
        FormHelperTextProps={{
          className: errorType === 'floating' ? 'Mui-error-floating' : '',
          sx: FormHelperTextSx,
        }}
      />
    </StyledTooltip>
  );
}
