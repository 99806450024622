import { Theme } from '@modules/common/types/general';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference } from '@modules/userPreferences/hooks';
import { Box, Dialog, Stack, Typography, dialogClasses } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  open: boolean;
  title?: string;
  onClose?: () => void;

  doneButton?: ReactNode;
  children?: ReactNode;
};

const dialogSx = {
  display: 'flex',
  flexDirection: 'column',

  [`& .${dialogClasses.paper}`]: {
    minHeight: '85vh',
    maxHeight: '85vh',
    minWidth: '79vw',
    maxWidth: '79vw',
  },
};

export function FullDialog({ doneButton, children, open, title, onClose }: Props) {
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => onClose?.()}
      sx={dialogSx}
      className={theme === Theme.DARK ? 'dark' : ''}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        padding={5}
        borderBottom='1px solid black'
        borderColor='divider'
      >
        <Typography fontSize={18} fontWeight={500} lineHeight='21px'>
          {title}
        </Typography>
        {doneButton}
      </Stack>
      <Box display='flex' flexGrow={1} overflow='hidden'>
        {children}
      </Box>
    </Dialog>
  );
}
