import { GeneratedLocation, GeneratedLoadPosition } from '@modules/floorplanService';

export const getShapeIdFromLocation = (location: GeneratedLocation) =>
  location.areaName?.split('.').at(0)?.split('_').at(0);

export const getGateIdFromLocation = (location: GeneratedLocation) =>
  location.locationName.substring(0, location.locationName.lastIndexOf('_'));

export const getShapeNameFromLoadPositon = (loadPosition: GeneratedLoadPosition) => {
  const underscoreIndex = loadPosition.loadPositionName.lastIndexOf('_');
  return loadPosition.loadPositionName.substring(0, underscoreIndex);
};

export const parseGateId = (id: string) => {
  const hyphenIndex = id.lastIndexOf('-');
  const areaNameWithVehicleId = id.substring(0, hyphenIndex);
  const underscoreIndex = areaNameWithVehicleId.lastIndexOf('_');

  return {
    shapeName: areaNameWithVehicleId.substring(0, underscoreIndex),
    endpointName: id.substring(underscoreIndex + 1),
  };
};
