import FolderZipIcon from '@mui/icons-material/FolderZip';
import ImageIcon from '@mui/icons-material/Image';
import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { download } from '@modules/common/helpers/browser';
import { groupNameSelector } from '@modules/floorplan';
import { OutputFiles } from '@modules/floorplanService';
import { useZoomButton } from '@modules/workspace/components/ZoomButton/useZoomButton';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { FormattedWarning, Warning } from '@/modules/floorplanService/types';
import { Box } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';

type Props = {
  output: OutputFiles;
  warnings: Warning[];
  formattedWarnings: FormattedWarning[];
};

export function ExportOutput({ output, warnings, formattedWarnings }: Props) {
  const { t } = useTranslation(['interface']);
  const { zoomFitShape } = useZoomButton();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);
  const name = useRecoilValue(groupNameSelector);

  const handleWarningClick = useCallback(
    (id) => {
      zoomFitShape(id);
      setSelectedShapesIds([id]);
    },
    [setSelectedShapesIds, zoomFitShape],
  );

  const handleDownloadZip = useCallback(() => {
    download(output.zipFile, `${name}.zip`);
  }, [name, output.zipFile]);

  const openWindow = useCallback((file: File) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    window.open(URL.createObjectURL(file), '_blank').focus();
  }, []);

  const getSeverityStyles = (severity: string) => {
    const normalize = severity.toLowerCase();
    switch (normalize) {
      case 'error':
        return {
          backgroundColor: '#ffe6e6',
          color: '#d32f2f',
          mb: 1,
          borderLeft: '6px solid #d32f2f',
          '&:hover': {
            borderLeft: '8px solid #d32f2f', // Increase thickness on hover
          },
        };
      case 'warning':
        return {
          backgroundColor: '#fff4e5',
          color: '#ff9800',
          borderLeft: '6px solid #ff9800',
          mb: 1,
          '&:hover': {
            borderLeft: '8px solid #ff9800',
          },
        };
      case 'info':
        return {
          backgroundColor: '#e7f3ff',
          color: '#1976d2',
          borderLeft: '6px solid #1976d2',
          mb: 1,
          '&:hover': {
            borderLeft: '8px solid #1976d2',
          },
        };
      default:
        return {};
    }
  };

  return (
    <List sx={{ maxWidth: '500px', marginTop: '0.4em' }}>
      {output.zipFile && (
        <ListItem disablePadding>
          <ListItemButton onClick={handleDownloadZip}>
            <ListItemIcon>
              <FolderZipIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('interface:export.download_prompt', 'Download the output zip file')}
            />
          </ListItemButton>
        </ListItem>
      )}
      <Divider />
      <ListSubheader sx={{ '&:hover': { cursor: 'default' } }}>
        {t('interface:export.files_label', 'Files')}
      </ListSubheader>
      {output.files &&
        output.files.map((file: File) => (
          <ListItem disablePadding key={file.name} sx={{ ...getSeverityStyles("info") }}>
            <ListItemButton onClick={() => openWindow(file)}>
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary={file.name} />
            </ListItemButton>
          </ListItem>
        ))}
      <Divider />
      <ListSubheader sx={{ '&:hover': { cursor: 'default' } }}>
        {t('interface:notifications.title', 'notifications')}
      </ListSubheader>
      {warnings.length > 0 && formattedWarnings.length === 0
        ? <Stack alignItems="center">
          <CircularProgress />
        </Stack>
        : formattedWarnings.map((warning) => (
          <div key={warning.sequence}>
            <ListItem onClick={() => warning.shapes.map((shape) => (
              handleWarningClick(shape.id)
            ))} sx={{ ...getSeverityStyles(warning.severity), pr: 2, pl: 2, cursor: "pointer", }}>
              <ListItemText>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>{warning.errorCode}: {warning.title}</Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 1 }}>
                    {warning.description}
                  </Typography>
                  <Box display="flex" alignItems="center" gap={0.5}>
                    {warning.readMore && (
                      <Typography variant="body2" color="textSecondary">
                        <Link href={warning.readMore} target="_blank" rel="noopener noreferrer" color="textSecondary">
                          {t('interface:export.read_more', 'Read more')}
                        </Link>
                      </Typography>
                    )}
                    <DescriptionIcon fontSize="small" />
                  </Box>
                </Box>
              </ListItemText>
              <Typography variant="caption" sx={{
                color: "textSecondary",
                position: "absolute",
                top: 4,
                right: 8,
              }}>
                {warning.timeStamp}
              </Typography>
            </ListItem>
          </div>
        ))}
    </List>
  );
}
