import { useRecoilCallback } from 'recoil';
import { areasAtom } from '../../../../store/recoil/shapes/area';
import { allHighwaysSelector } from '../../../../store/recoil/shapes/highway';
import { positionsAtom } from '../../../../store/recoil/shapes/positions/atom';
import { getIncorrectlyConnectedShapeIds } from '../helpers';
import { prevalidationState } from '../store';
import { allConnectionsSelector } from '@modules/connections/connections';

export const useIncorrectlyConnectedShapesToRoadIdsValidator = () => {
  const validateIncorrectlyConnectedShapeToRoadIds = useRecoilCallback(
    ({ snapshot, set }) =>
      async (syncResultsWithStore = true) => {
        const [allAreaIds, allPositionIds, allHighways, allConnections] = await Promise.all([
          snapshot.getPromise(areasAtom),
          snapshot.getPromise(positionsAtom),
          snapshot.getPromise(allHighwaysSelector),
          snapshot.getPromise(allConnectionsSelector),
        ]);
        const areasAndPositionsIds = [...allAreaIds, ...allPositionIds];

        const incorrectlyConnectedShapeIds = getIncorrectlyConnectedShapeIds(
          areasAndPositionsIds,
          allHighways,
          allConnections,
        );

        if (syncResultsWithStore) {
          set(prevalidationState, (current) => {
            if (
              current.incorrectlyConnectedShapeToRoadIds.length === 0 &&
              incorrectlyConnectedShapeIds.length === 0
            ) {
              return current;
            }

            return {
              ...current,
              incorrectlyConnectedShapeIds,
            };
          });
        }

        return incorrectlyConnectedShapeIds;
      },
    [],
  );

  return {
    validateIncorrectlyConnectedShapeToRoadIds,
  };
};
