import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { useDeleteShape } from '@/modules/workspace/hooks';
import { useCopyPaste } from '@/modules/workspace/hooks/useCopyPaste';
import { PropertiesHeader } from '@common/components/PropertiesHeader';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { useSelectedShape } from '@modules/workspace/hooks/useSelectedShape';
import { CONTEXT, contextState } from '@recoil/input';
import { allShapesSelector } from '@recoil/shapes';
import { selectedShapesIdsState, selectedShapesState } from '@recoil/shapes/selected';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { useModal } from '@modules/common/hooks/useModal';
import {
  allGroupsSelector,
  selectedGroupsSelector,
  shapeGroupNameSelector,
} from '../../modules/shapeGroups';
import { AlignPanel } from '../../modules/workspace/components/AlignPanel';
import { shapeNameSelector } from '../../store/recoil/shape';
import { propertiesHeaderNameSelector } from './store/header';

function Header() {
  const { t } = useTranslation();
  const propertiesHeaderName = useRecoilValue(propertiesHeaderNameSelector);
  const { openModal } = useModal();
  const { createShapeCopy } = useCopyPaste();
  const selectedShapesIds = useRecoilValue(selectedShapesIdsState);
  const selectedGroups = useRecoilValue(selectedGroupsSelector);
  const setContext = useSetRecoilState(contextState);
  const { deleteMultipleShapes } = useDeleteShape();
  const { deselect } = useSelectedShape();
  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation();
  const mode = useRecoilValue(modeSelector);

  const onDeleteClick = useCallback(async () => {
    await deselect();
    await deleteMultipleShapes(selectedShapesIds);
    saveFloorPlan();
    runPreValidation();
  }, [deleteMultipleShapes, saveFloorPlan, runPreValidation, selectedShapesIds, deselect]);

  const onNameChange = useRecoilCallback(
    ({ snapshot, set }) =>
      async (value: string) => {
        if (!value) {
          return { error: t('errors:area.name.required') };
        }

        const [shapes, selectedShapes, allGroups, selectedGroups] = await Promise.all([
          snapshot.getPromise(allShapesSelector),
          snapshot.getPromise(selectedShapesState),
          snapshot.getPromise(allGroupsSelector),
          snapshot.getPromise(selectedGroupsSelector),
        ]);

        const currentShape = selectedShapes[0];
        const currentGroup = selectedGroups.at(0);

        const groupIsExclusivelySelected =
          currentGroup && currentGroup?.children.length === selectedShapes.length;

        const unique = groupIsExclusivelySelected
          ? allGroups
              .filter((item) => item.id !== currentGroup.id)
              .every((item) => item.name !== value)
          : shapes
              .filter((item) => item.id !== currentShape.id)
              .every((item) => item.name !== value);

        if (!unique) {
          return groupIsExclusivelySelected
            ? { error: t('errors:shape_group.name.duplicated') }
            : { error: t('errors:area.name.duplicated') };
        }

        // update name for single group / single shape
        if (groupIsExclusivelySelected) {
          set(shapeGroupNameSelector(currentGroup.id), value);
        } else if (selectedShapes.length === 1) {
          set(shapeNameSelector(currentShape.id), value);
        }
      },
    [t],
  );

  const onDuplicateMultiClick = useCallback(() => {
    openModal(['MULTI_COPY']);
  }, [openModal]);

  return (
    <>
      <PropertiesHeader
        // @ts-expect-error strictNullChecks. Pls fix me
        name={propertiesHeaderName}
        disabled={mode !== WorkspaceMode.EDITABLE}
        onDeleteClick={onDeleteClick}
        onDuplicateMultiClick={selectedShapesIds.length === 1 ? onDuplicateMultiClick : undefined}
        editable={
          selectedShapesIds.length === 1 ||
          (selectedGroups.length === 1 &&
            selectedGroups[0].children.length === selectedShapesIds.length)
        }
        // @ts-expect-error strictNullChecks. Pls fix me
        onNameChange={onNameChange}
        onBlur={() => setContext(CONTEXT.WORKSPACE)}
        onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
      />
      {selectedShapesIds.length > 1 && <AlignPanel />}
    </>
  );
}

export default Header;
